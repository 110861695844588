import { useDisclosure } from "@mantine/hooks"
import {
	Modal,
	Group,
	Button,
	Flex,
	LoadingOverlay,
	Box,
	Title
} from "@mantine/core"
import { FC, useEffect, useState } from "react"
import { TextInput, Checkbox, Text, Select } from "@mantine/core"
import { useForm } from "@mantine/form"
import styled from "@emotion/styled"
import { UserType } from "../../../types/User"
import { ITEM_SPLITTER } from "../../../system/constants"
import { UserForm } from "../../Forms/UserForm"

type AddUserModalProps = {
	close: () => void
	opened: boolean
	emptyFlatNumber: string
	handleSubmit: (data: UserType) => void
}

export const isValidNumbersWithSeparator = (value: string) => {
	const valuesElements = value.replace(/\s/g, "").split(ITEM_SPLITTER)
	return valuesElements.every((value) => !Number.isNaN(+value))
}

export const AddUserModal: FC<AddUserModalProps> = ({
	opened,
	close,
	emptyFlatNumber
}) => {
	return (
		<Modal
			opened={opened}
			onClose={close}
			centered
			size="lg"
			withCloseButton={false}
		>
			<Title order={2}>Add new user</Title>
			<UserForm
				onClose={close}
				emptyFlatNumber={emptyFlatNumber}
				formMode="create"
			/>
		</Modal>
	)
}
