import { ThemeProvider } from "./system/Theme"
import { AppRooter } from "./system/AppRouter"

const App = () => {
	return (
		<ThemeProvider>
			<AppRooter />
		</ThemeProvider>
	)
}

export default App
