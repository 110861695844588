import { Navbar } from "@mantine/core"
import { NavHeader } from "./NavHeader"
import { NavLinks } from "./NavLinks"
import { NavFooter } from "./NavFooter"

export const NavBar = () => {
	return (
		<Navbar
			width={{ base: 300 }}
			height={"100%"}
			p="lg"
			// style={{ paddingTop: 50 }}
		>
			<NavHeader />
			<NavLinks />
			<NavFooter />
		</Navbar>
	)
}
