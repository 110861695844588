import styled from "@emotion/styled"
import {
	Button,
	Group,
	Paper,
	Skeleton,
	Stack,
	Text,
	Title
} from "@mantine/core"
import { IconArrowLeft } from "@tabler/icons-react"
import { FC, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useUsersStore } from "../../../hooks/useUsersStore"
import { UserForm } from "../../Forms/UserForm"
import { format } from "date-fns"
import { UserType } from "../../../types/User"

const FlexWrapper = styled("div")({
	display: "flex",
	flexDirection: "column",
	width: "100%",
	height: "100%",
	alignItems: "center"
})
const FlexContainer = styled("div")({
	display: "flex",
	width: "100%",
	height: "100%",
	justifyContent: "space-between"
})

export const UserPreview = () => {
	const navigate = useNavigate()
	const { userId, mode } = useParams()
	const getUser = useUsersStore((state) => state.getUser)
	const handleReturn = () => navigate("/users")
	const [selectedUser, setSelectedUser] = useState<UserType | null>(null)

	useEffect(() => {
		if (userId) {
			getUser(userId).then((data) => {
				setSelectedUser(data)
			})
		}
	}, [userId, mode])

	return (
		<FlexWrapper>
			<ReturnButton onClick={handleReturn} />
			<FlexContainer>
				<Paper
					shadow="lg"
					p="lg"
					style={{
						width: 600,
						overflow: "hidden",
						overflowY: "auto",
						minWidth: 600,
						display: "flex",
						flexDirection: "column",
						height: "100%",
						alignItems: "flex-start"
					}}
				>
					<FlatPreview selectedUser={selectedUser} />
					<UserForm selectedUser={selectedUser} />
				</Paper>
				<div
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-end",
						justifyContent: "space-between",
						flex: 1,
						marginLeft: 40
					}}
				>
					<Paper
						shadow="lg"
						p="lg"
						style={{
							width: "100%",
							overflow: "hidden",
							overflowY: "auto",
							height: 400,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							opacity: 0
						}}
					>
						<Text c="dimmed"> Work in progress</Text>
					</Paper>
				</div>
			</FlexContainer>
		</FlexWrapper>
	)
}

const ReturnButton: FC<{ onClick: () => void }> = ({ onClick }) => {
	return (
		<div style={{ width: "100%", marginBottom: 10 }}>
			<Button variant="subtle" leftIcon={<IconArrowLeft />} onClick={onClick}>
				Return to the table
			</Button>
		</div>
	)
}

const FlatPreview: FC<{ selectedUser: UserType | null }> = ({
	selectedUser
}) => {
	return (
		<Skeleton visible={!selectedUser}>
			<div
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "flex-start"
				}}
			>
				<Title order={1}>{selectedUser?.flatNumber}</Title>
				<Stack spacing="xs" align="right" style={{ textAlign: "right" }}>
					<Text c="dimmed" fz="sm">
						last updated:{" "}
						{selectedUser?.flatNumber &&
							format(+selectedUser.lastUpdated, "dd/MM/yyyy HH:mm")}
					</Text>
					{selectedUser?.lastLogin && (
						<Text c="dimmed" fz="sm">
							last login: {format(+selectedUser?.lastLogin, "dd/MM/yyyy HH:mm")}
						</Text>
					)}
				</Stack>
			</div>
		</Skeleton>
	)
}
