import {
	MantineProvider,
	ColorSchemeProvider,
	ColorScheme
} from "@mantine/core"
import { FC, PropsWithChildren, useState } from "react"

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
	const [colorScheme, setColorScheme] = useState<ColorScheme>("light")
	const toggleColorScheme = (value?: ColorScheme) => {
		const nextColorScheme = value || (colorScheme === "dark" ? "light" : "dark")
		setColorScheme(nextColorScheme)
	}

	return (
		<ColorSchemeProvider
			colorScheme={colorScheme}
			toggleColorScheme={toggleColorScheme}
		>
			<MantineProvider
				withGlobalStyles
				withNormalizeCSS
				theme={{ colorScheme }}
			>
				{children}
			</MantineProvider>
		</ColorSchemeProvider>
	)
}
