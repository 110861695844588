import {
	TextInput,
	Checkbox,
	Button,
	Group,
	Box,
	Alert,
	Text,
	Stack,
	Anchor,
	Space
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { useCurrentUserStore } from "../../hooks/useCurrentUser"
import { firebaseAuth } from "../../api/auth"
import {
	ConfirmationResult,
	RecaptchaVerifier,
	getAuth,
	signInWithPhoneNumber
} from "firebase/auth"
import {
	IconAlertCircle,
	IconAt,
	IconPhone,
	IconBrandTelegram
} from "@tabler/icons-react"
import { getParsedPhoneNumber } from "../../hooks/useCurrentUser"
import { FC, useEffect, useState } from "react"
import OtpInput from "react-otp-input"
import { Navigate, redirect } from "react-router-dom"

declare global {
	interface Window {
		recaptchaVerifier: RecaptchaVerifier
		confirmationResult: ConfirmationResult
	}
}

type SubmitType = {
	phoneNumber: string
	termsAndConditions: boolean
}
type LoginFormPropsType = {
	setIsPageLoading: (val: boolean) => void
}

export const LoginForm: FC<LoginFormPropsType> = ({ setIsPageLoading }) => {
	const [isValidating, setIsValidating] = useState(false)
	const [otp, setOtp] = useState("")
	const [alert, setAlert] = useState({ title: "", color: "", message: "" })
	const [showOTP, setShowOTP] = useState(false)
	const validateUserByPhone = useCurrentUserStore(
		(state) => state.validateUserByPhone
	)

	const resetAlert = () => {
		setAlert({ message: "", title: "", color: "" })
	}

	const form = useForm({
		initialValues: {
			phoneNumber: "",
			termsAndConditions: false
		},
		validate: {
			phoneNumber: (value) =>
				!!value ? null : "Please enter your phone number",
			termsAndConditions: (value) => (!!value ? null : "Please check and agree")
		}
	})

	const handleSubmit = async (values: SubmitType) => {
		setIsValidating(true)
		const [phoneNumber, phoneId] = getParsedPhoneNumber(values.phoneNumber)
		const isValidUser = await validateUserByPhone(phoneId)
		if (!isValidUser) {
			setAlert({
				color: "red",
				message: "You are not allowed to access. Please contact valde@ab38.lv",
				title: "Not allowed"
			})
			setIsValidating(false)
			setTimeout(() => {
				resetAlert()
			}, 5000)
			return
		}
		setIsValidating(false)
		onCaptchaVerify()
		firebaseAuth.useDeviceLanguage()

		const appVerifier = window.recaptchaVerifier

		signInWithPhoneNumber(firebaseAuth, phoneNumber, appVerifier)
			.then((confirmationResult) => {
				window.confirmationResult = confirmationResult
				appVerifier.clear()
				setShowOTP(true)
				setAlert({
					color: "green",
					message: `A message with a verification code has been sent to ${phoneNumber}. `,
					title: "Success"
				})
			})
			.catch((error) => {
				setAlert({
					color: "red",
					message: "Cannot proceed authorization. Please contact valde@ab38.lv",
					title: "Error"
				})
				console.error(error)
			})
	}

	function onCaptchaVerify() {
		try {
			if (!window.recaptchaVerifier) {
				window.recaptchaVerifier = new RecaptchaVerifier(
					"recaptcha-container",
					{
						size: "invisible",
						callback: () => {
							// handleSubmit(form.values)
						},
						"expired-callback": () => {}
					},
					firebaseAuth
				)
			}
		} catch (e) {
			console.log("CAPTCHA ERROR")
		}
	}

	const handleOTPVerify = () => {
		setIsPageLoading(true)

		window.confirmationResult
			.confirm(otp)
			.then(async (res) => {
				setIsPageLoading(false)
				const [phoneNumber, phoneId] = getParsedPhoneNumber(
					form.values.phoneNumber
				)
				// await loadUser(phoneId)
			})
			.catch((err) => {
				setIsPageLoading(false)
				setAlert({
					color: "red",
					message: `Wrong verification code`,
					title: "Error"
				})
			})
	}

	return (
		<Stack pt={15} pl={60} pr={60}>
			<div style={{ height: 100 }}>
				{alert.message && (
					<Alert color={alert.color} title={alert.title}>
						{alert.message}
					</Alert>
				)}
			</div>
			<div id="recaptcha-container"></div>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				{showOTP && (
					<OtpInput
						value={otp}
						onChange={(value) => {
							setOtp(value)
							resetAlert()
						}}
						numInputs={6}
						renderSeparator={
							<span>
								<Space w="md" />
							</span>
						}
						containerStyle={{ justifyContent: "center" }}
						renderInput={(props) => (
							<input
								{...props}
								style={{
									width: 40,
									height: 40,
									textAlign: "center",
									fontSize: 20
								}}
							/>
						)}
					/>
				)}
				{!showOTP && (
					<TextInput
						disabled={isValidating}
						inputMode="tel"
						size="md"
						label="Phone number"
						placeholder="+37128899561"
						labelProps={{ style: { marginBottom: 10 } }}
						icon={<IconPhone />}
						{...form.getInputProps("phoneNumber")}
					/>
				)}
				{!showOTP && (
					<Checkbox
						mt={20}
						{...form.getInputProps("termsAndConditions")}
						label={
							<Text>
								I agree with{" "}
								<Anchor href="https://ab38.lv" target="_blank">
									Terms and Conditions
								</Anchor>
							</Text>
						}
					/>
				)}

				<Group position="center" mt="40px">
					{!showOTP && (
						<Button
							type="submit"
							size="md"
							leftIcon={<IconBrandTelegram />}
							loading={isValidating}
						>
							Send verification code
						</Button>
					)}
					{showOTP && (
						<Button size="md" onClick={handleOTPVerify}>
							Verify code
						</Button>
					)}
				</Group>
			</form>
		</Stack>
	)
}
