import { Button, Flex, Group, Paper, Select, TextInput } from "@mantine/core"
import { IconPlus } from "@tabler/icons-react"
import { useUsersStore } from "../hooks/useUsersStore"
import { FC } from "react"
import {
	FLOORS_PER_CORPUS,
	MEMBERSHIP_STATUSES,
	OWNERSHIP_STATUSES
} from "../system/constants"
import { useMediaQuery } from "@mantine/hooks"

export const Filters: FC<{ openAddUserModal: () => void }> = ({
	openAddUserModal
}) => {
	const handleFiltersChange = useUsersStore(
		(state) => state.handleFiltersChange
	)
	const searchValue = useUsersStore((state) => state.filters.search)
	const floorValue = useUsersStore((state) => state.filters.floor)
	const ownershipStatusValue = useUsersStore(
		(state) => state.filters.ownershipStatus
	)
	const biedribaStatusValue = useUsersStore(
		(state) => state.filters.biedribaStatus
	)
	const hideFirst = useMediaQuery("(max-width: 1300px)")
	const hideSecond = useMediaQuery("(max-width: 1100px)")
	const isSmallest = useMediaQuery("(max-width: 900px)")
	const showMoreButton = hideFirst || hideSecond

	return (
		<Paper
			shadow="sm"
			radius="md"
			style={{
				height: "100px",
				width: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				paddingBottom: 20
			}}
			p="sm"
		>
			<Flex
				mih={50}
				gap="xl"
				justify="space-between"
				align="flex-end"
				direction="row"
				wrap={"wrap"}
				w={"inherit"}
			>
				<TextInput
					label="Search"
					style={{ width: 200, maxHeight: 60 }}
					placeholder="Name, Surname, Flat number, Parking number"
					size="xs"
					value={searchValue}
					onChange={(event) => {
						// setSearchValue(event.target.value)
						handleFiltersChange("search", event.target.value)
					}}
				/>
				{!hideSecond && (
					<Select
						label="Floor Number"
						defaultValue={FLOORS_PER_CORPUS[0]}
						value={floorValue}
						data={FLOORS_PER_CORPUS}
						onChange={(value) => {
							if (value) {
								handleFiltersChange("floor", value)
							}
						}}
						size="xs"
					/>
				)}
				{!hideFirst && (
					<Select
						value={ownershipStatusValue}
						defaultValue={OWNERSHIP_STATUSES[0]}
						label="Ownership Status"
						data={OWNERSHIP_STATUSES}
						size="xs"
						onChange={(value) => {
							if (value) {
								handleFiltersChange("ownershipStatus", value)
							}
						}}
					/>
				)}
				{!hideFirst && (
					<Select
						value={biedribaStatusValue}
						defaultValue={MEMBERSHIP_STATUSES[0]}
						label="Membership Status"
						data={MEMBERSHIP_STATUSES}
						size="xs"
						onChange={(value) => {
							if (value) {
								handleFiltersChange("biedribaStatus", value)
							}
						}}
					/>
				)}
				<Group noWrap>
					{showMoreButton && (
						<Button
							size={isSmallest ? "xs" : "md"}
							leftIcon={<IconPlus />}
							style={{ marginLeft: 20 }}
							onClick={openAddUserModal}
						>
							More filters
						</Button>
					)}
					<Button
						size={isSmallest ? "xs" : "md"}
						leftIcon={<IconPlus />}
						style={{ marginLeft: 20 }}
						onClick={openAddUserModal}
					>
						Add User
					</Button>
				</Group>
			</Flex>
		</Paper>
	)
}
