import { Table as TableComponent } from "@mantine/core"
import { collection, getDocs } from "firebase/firestore"
import { useNavigate } from "react-router-dom"
import { FC } from "react"
import { UserType } from "../types/User"
import { getFloorNumberString } from "../hooks/useUsersStore/utils"

const getFlatNumber = (flatNumber: string) => {
	return flatNumber ? flatNumber : "-"
}

type TablePropsType = {
	setEmptyFlatNumber: (number: string) => void
	openAddUserModal: () => void
	users: UserType[]
}
export const Table: FC<TablePropsType> = ({
	users,
	setEmptyFlatNumber,
	openAddUserModal
}) => {
	let navigate = useNavigate()

	const handleClick = (id = "", flat = "") => {
		if (id) return navigate(`view/${id}`)
		setEmptyFlatNumber(flat)
		openAddUserModal()
	}

	const rows = users.map((element) => (
		<tr
			style={{
				maxHeight: 60,
				minHeight: 60,
				cursor: "pointer"
			}}
			key={`${Math.random() * 1000000000}`}
			onClick={() => {
				handleClick(element.id, element.flatNumber)
			}}
		>
			<td
				style={{
					background: element.id ? "inherit" : "rgba(255, 227, 242, 0.07)"
				}}
			>
				{getFlatNumber(element.flatNumber)}
			</td>
			<td
				style={{
					background: element.id ? "inherit" : "rgba(255, 227, 242, 0.07)"
				}}
			>
				{getFloorNumberString(element.flatNumber)}
			</td>
			<td
				style={{
					background: element.id ? "inherit" : "rgba(255, 227, 242, 0.07)"
				}}
			>
				{element.phoneNumber || ""}
			</td>
			<td
				style={{
					background: element.id ? "inherit" : "rgba(255, 227, 242, 0.07)"
				}}
			>
				{element.name || ""}
			</td>
			<td
				style={{
					background: element.id ? "inherit" : "rgba(255, 227, 242, 0.07)"
				}}
			>
				{element.surname || ""}
			</td>
		</tr>
	))

	return (
		<TableComponent highlightOnHover withColumnBorders verticalSpacing="xs">
			<thead>
				<tr style={{ maxHeight: 60, minHeight: 60 }}>
					<th style={{ width: "8%" }}>Flat </th>
					<th style={{ width: "8%" }}>Floor</th>
					<th style={{ width: "20%" }}>Phone Number</th>
					<th style={{ width: "30%" }}>Name</th>
					<th>Surname</th>
				</tr>
			</thead>
			<tbody>{rows}</tbody>
		</TableComponent>
	)
}
