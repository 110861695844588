/*
TODO [USERFORM]
1. Rename component
2. Add generic props to combine with Dialog
3. Add Alerts on Delete

*/

import {
	Group,
	Button,
	MultiSelect,
	Skeleton,
	MantineTheme
} from "@mantine/core"
import { FC, useEffect, useState } from "react"
import { TextInput, Checkbox, Select } from "@mantine/core"
import { useForm } from "@mantine/form"
import styled from "@emotion/styled"
import { FirebaseUserRecord, UserRoleType, UserType } from "../../types/User"
import { ITEM_SPLITTER } from "../../system/constants"
import { useUsersStore } from "../../hooks/useUsersStore"
import { useNavigate, useParams } from "react-router-dom"
import { IconDeviceFloppy } from "@tabler/icons-react"
import { IconCheck } from "@tabler/icons-react"
import { IconTrash } from "@tabler/icons-react"
import { ConfirmationDialog } from "../Modals/Confirmation"
import { IconCirclePlus } from "@tabler/icons-react"
import { notifications } from "@mantine/notifications"
import { useDisclosure } from "@mantine/hooks"

const StyledGroup = styled(Group)({
	marginBottom: 10
})
const Gap = styled("div")({
	height: 20
})
const Section = styled("div")({
	marginTop: 10,
	marginBottom: 20,
	flexGrow: 1
})

type UserFormProps = {
	formMode?: "create" | "update"
	selectedUser?: UserType | null
	emptyFlatNumber?: string
	onSubmit?: (data: FirebaseUserRecord) => void
	onClose?: () => void
}

export const isValidNumbersWithSeparator = (value: string) => {
	const valuesElements = value.replace(/\s/g, "").split(ITEM_SPLITTER)
	return valuesElements.every((value) => !Number.isNaN(+value))
}

const prepareUserDataValues = (userData: UserType): FormTypeValues => {
	const formValues = { ...userData } as Partial<UserType>
	delete formValues.id
	return {
		...(formValues as FirebaseUserRecord),
		role: formValues?.role?.split(ITEM_SPLITTER) as UserRoleType[]
	}
}
const parseUserDataValues = (userData: FormTypeValues): FirebaseUserRecord => ({
	...userData,
	role: userData.role.join(ITEM_SPLITTER) as UserRoleType
})

export type FormTypeValues = Omit<FirebaseUserRecord, "role" | "id"> & {
	role: Array<UserRoleType>
}
const EMPTY_VALUES: FormTypeValues = {
	email: "", //y
	lastUpdated: new Date().getTime().toString(), //y
	// lastUpdated: "1678729946684", //y
	role: ["user"],
	isActivated: false, //y
	isDisabled: false, //y
	notes: "", //y
	isMemberOfBiedriba: false,
	isBoardMember: false,
	name: "", //y
	surname: "", //y
	phoneNumber: "", //y
	corpusNumber: "0", //y
	externalParking: "", //y
	internalParking: "", //y
	flatNumber: "", //y
	ownershipStatus: "owner"
}

const getDisabledStyles = (theme: MantineTheme) => ({
	input: {
		":disabled": {
			opacity: "1 !important",
			color:
				theme.colorScheme === "light" ? "#000 !important" : "#C1C2C5 !important"
		}
	}
})
export const UserForm: FC<UserFormProps> = ({
	onSubmit,
	onClose,
	emptyFlatNumber = "",
	selectedUser,
	formMode = "update"
}) => {
	const navigate = useNavigate()
	const { mode, userId } = useParams()
	const handleAddUser = useUsersStore((state) => state.handleAddUser)
	const handleDeleteUser = useUsersStore((state) => state.handleDeleteUser)
	const handleUpdateUser = useUsersStore((state) => state.handleUpdateUser)
	const [loading, setLoading] = useState(false)
	const [confirmationOpen, { toggle: confirmationToggle }] =
		useDisclosure(false)
	const isEditMode = mode === "edit"
	const showSkeleton = !selectedUser && formMode === "update"
	const isFieldDisabled = !isEditMode && formMode === "update"

	const form = useForm<FormTypeValues>({
		initialValues: EMPTY_VALUES,
		validate: {
			phoneNumber: (value) => (!!value ? null : "Missing phone number"),
			name: (value) => (!!value ? null : "Missing name"),
			surname: (value) => (!!value ? null : "Missing surname"),
			flatNumber: (value) =>
				isValidNumbersWithSeparator(value) ? null : "Wrong flat number format"
		}
	})

	useEffect(() => {
		if (emptyFlatNumber) {
			form.setFieldValue("flatNumber", emptyFlatNumber)
		}
	}, [emptyFlatNumber])

	useEffect(() => {
		if (selectedUser) {
			form.setValues(prepareUserDataValues({ ...selectedUser }))
		}
	}, [selectedUser])

	const onUpdateUser = async (data: FirebaseUserRecord) => {
		if (userId) {
			await handleUpdateUser(userId, data)
			setLoading(false)
			if (onSubmit) onSubmit(data)
			navigate(`view/${userId}`)
		}
	}
	const onCreateUser = async (data: FirebaseUserRecord) => {
		await handleAddUser(data)
		setLoading(false)
		form.reset()
		if (onSubmit) onSubmit(data)
		if (onClose) onClose()
		notifications.show({
			title: "User has been created",
			message: `New user: ${data.name} ${data.surname}`,
			color: "teal",
			icon: <IconCheck />
		})
	}

	const onDeleteUser = async () => {
		if (userId) await handleDeleteUser(userId)
		navigate("/users")
		confirmationToggle()
		notifications.show({
			message: "User has been removed",
			color: "red",
			icon: <IconTrash />
		})
	}

	const toggleFormMode = () => {
		const prefix = isEditMode ? "view" : "edit"
		form.reset()
		navigate(`${prefix}/${userId}`)
	}

	const handleFormSubmit = () => {
		return form.onSubmit(async (data) => {
			setLoading(true)
			const transformedData = parseUserDataValues(data)
			return selectedUser
				? await onUpdateUser(transformedData)
				: await onCreateUser(transformedData)
		})
	}

	return (
		<>
			<form
				onSubmit={handleFormSubmit()}
				style={{ width: "100%", display: "flex", flexDirection: "column" }}
			>
				<Section>
					<Section>
						<StyledGroup position="center" grow>
							<Skeleton visible={showSkeleton}>
								<TextInput
									label="Name"
									variant={isFieldDisabled ? "filled" : "default"}
									disabled={isFieldDisabled}
									{...form.getInputProps("name")}
									styles={(theme) => ({ ...getDisabledStyles(theme) })}
								/>
							</Skeleton>
							<Skeleton visible={showSkeleton}>
								<TextInput
									label="Surname"
									variant={isFieldDisabled ? "filled" : "default"}
									disabled={isFieldDisabled}
									{...form.getInputProps("surname")}
									styles={(theme) => ({ ...getDisabledStyles(theme) })}
								/>
							</Skeleton>
						</StyledGroup>
						<Skeleton visible={showSkeleton}>
							<StyledGroup position="center" grow>
								<TextInput
									label="Email"
									placeholder="your@email.com"
									variant={isFieldDisabled ? "filled" : "default"}
									disabled={isFieldDisabled}
									styles={(theme) => ({ ...getDisabledStyles(theme) })}
									{...form.getInputProps("email")}
								/>
								<TextInput
									label="Phone number"
									placeholder="21332122"
									variant={isFieldDisabled ? "filled" : "default"}
									disabled={isFieldDisabled}
									styles={(theme) => ({ ...getDisabledStyles(theme) })}
									{...form.getInputProps("phoneNumber")}
								/>
							</StyledGroup>
						</Skeleton>
						<Gap />
						<Skeleton visible={showSkeleton}>
							<Section>
								<StyledGroup position="center" grow>
									<Select
										description={"Specify ownership of flat/flats for user"}
										data={["owner", "renter"]}
										label="Ownership status"
										variant={isFieldDisabled ? "filled" : "default"}
										disabled={isFieldDisabled}
										styles={(theme) => ({ ...getDisabledStyles(theme) })}
										{...form.getInputProps("ownershipStatus")}
									/>
									<TextInput
										description="You can add multiple flats using comma"
										label="Flat number"
										variant={isFieldDisabled ? "filled" : "default"}
										disabled={isFieldDisabled}
										styles={(theme) => ({ ...getDisabledStyles(theme) })}
										{...form.getInputProps("flatNumber")}
									/>
								</StyledGroup>
								<StyledGroup position="center" grow>
									<TextInput
										label="Street parking"
										variant={isFieldDisabled ? "filled" : "default"}
										disabled={isFieldDisabled}
										styles={(theme) => ({ ...getDisabledStyles(theme) })}
										{...form.getInputProps("externalParking")}
									/>
									<TextInput
										label="Covered parking"
										variant={isFieldDisabled ? "filled" : "default"}
										disabled={isFieldDisabled}
										styles={(theme) => ({ ...getDisabledStyles(theme) })}
										{...form.getInputProps("internalParking")}
									/>
								</StyledGroup>
							</Section>
						</Skeleton>
					</Section>
					<Gap />

					<Skeleton visible={showSkeleton}>
						<Section>
							<StyledGroup position="center" grow>
								<Select
									description="Living corpus of Metropolia apartments"
									data={["0", "1", "2", "3", "4"]}
									label="Corpus number"
									variant={isFieldDisabled ? "filled" : "default"}
									disabled={isFieldDisabled}
									zIndex={100000}
									dropdownPosition="top"
									styles={(theme) => ({ ...getDisabledStyles(theme) })}
									{...form.getInputProps("corpusNumber")}
								/>
								<MultiSelect
									data={["user", "admin", "stuff", "external"]}
									label="Roles"
									description="You can select multiple roles"
									dropdownPosition="top"
									variant={isFieldDisabled ? "filled" : "default"}
									disabled={isFieldDisabled}
									zIndex={1000}
									styles={(theme) => ({
										...getDisabledStyles(theme),
										value: {
											color: theme.colorScheme === "light" ? "#000" : "#FFF"
										}
									})}
									{...form.getInputProps("role")}
								/>
							</StyledGroup>
							<StyledGroup position="center" grow>
								<Checkbox
									mt="md"
									disabled={isFieldDisabled}
									label="Member of AB38 Biedriba"
									{...form.getInputProps("isMemberOfBiedriba", {
										type: "checkbox"
									})}
									styles={(theme) => ({
										label: {
											color:
												theme.colorScheme === "light"
													? "#000 !important"
													: "#FFF !important"
										}
									})}
								/>
								<Checkbox
									mt="md"
									disabled={isFieldDisabled}
									label="Board Member of AB38 Biedriba"
									{...form.getInputProps("isBoardMember", {
										type: "checkbox"
									})}
									styles={(theme) => ({
										label: {
											color:
												theme.colorScheme === "light"
													? "#000 !important"
													: "#FFF !important"
										}
									})}
								/>
							</StyledGroup>
						</Section>
					</Skeleton>
				</Section>
				<Skeleton visible={showSkeleton}>
					{selectedUser ? (
						<UpdateButtonGroup
							onDeleteUser={confirmationToggle}
							isEditMode={isEditMode}
							loading={loading}
							toggleFormMode={toggleFormMode}
						/>
					) : (
						<CreateButtonGroup loading={loading} onClose={onClose} />
					)}
				</Skeleton>
			</form>
			<ConfirmationDialog
				opened={confirmationOpen}
				onClose={confirmationToggle}
				onConfirm={onDeleteUser}
			/>
		</>
	)
}

type UpdateButtonGroupProps = {
	isEditMode: boolean
	onDeleteUser: () => void
	toggleFormMode: () => void
	loading: boolean
}
const UpdateButtonGroup: FC<UpdateButtonGroupProps> = ({
	isEditMode,
	onDeleteUser,
	toggleFormMode,
	loading
}) => {
	return (
		<Group mt="md" w={"100%"} position={!isEditMode ? "right" : "apart"}>
			<Group position="right" mt="md">
				{!!isEditMode && (
					<Button color="red" onClick={onDeleteUser} leftIcon={<IconTrash />}>
						Delete
					</Button>
				)}
			</Group>
			<Group position="right" mt="md">
				{!!isEditMode && (
					<Button color="gray" onClick={() => {}} disabled>
						Disable User
					</Button>
				)}
				{!!isEditMode && (
					<Button color="gray" onClick={toggleFormMode}>
						Cancel
					</Button>
				)}
				{isEditMode && (
					<Button
						type="submit"
						loading={loading}
						leftIcon={<IconDeviceFloppy />}
					>
						Save
					</Button>
				)}
				{!isEditMode && <Button onClick={toggleFormMode}>Update</Button>}
			</Group>
		</Group>
	)
}
type CreateButtonGroupProps = {
	onClose?: () => void
	loading: boolean
}
const CreateButtonGroup: FC<CreateButtonGroupProps> = ({
	onClose,
	loading
}) => {
	return (
		<Group position="right" mt="md">
			<Button color="gray" onClick={onClose}>
				Cancel
			</Button>
			<Button type="submit" loading={loading} leftIcon={<IconCirclePlus />}>
				Create
			</Button>
		</Group>
	)
}
