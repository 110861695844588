export const CORPUS_0_FLATS = [
	11, 12, 13, 14, 15, 21, 22, 23, 24, 25, 26, 27, 28, 31, 32, 33, 34, 35, 36,
	37, 38, 41, 42, 43, 44, 45, 46, 51, 52, 53, 54, 55, 56, 57, 58, 61, 62, 63,
	64, 65, 66, 67, 68, 71, 72, 73, 74, 75, 76, 78, 81, 82, 83, 84, 85, 86, 91,
	92, 93, 94, 95, 96, 97, 98, 101, 102, 103, 104, 105, 106, 107, 111, 112, 113,
	114, 115, 116, 117, 121, 122, 123, 124, 125, 126, 131, 132, 133, 134, 135,
	136, 141, 142, 143, 144, 145, 146, 151, 152, 153, 154, 155, 161, 162, 163,
	171, 172, 181
]

export const FLOORS_PER_CORPUS = [
	"All",
	"1",
	"2",
	"3",
	"4",
	"5",
	"6",
	"7",
	"8",
	"9",
	"10",
	"11",
	"12",
	"13",
	"14",
	"15",
	"16",
	"17",
	"18"
]

export const OWNERSHIP_STATUSES = ["All", "owner", "renter"]
export const MEMBERSHIP_STATUSES = ["All", "Biedriba member", "Board member"]
export const ITEMS_PER_PAGE = 11
export const ITEM_SPLITTER = ","
export const LV_PHONE_REGION_CODE = "+371"
