import {
	createBrowserRouter,
	RouteObject,
	RouterProvider
} from "react-router-dom"
import { Login } from "../pages/Login"
import { AppShell } from "./AppShell"
import { Users } from "../pages/Users"
import { useCurrentUserStore } from "../hooks/useCurrentUser"
import { IconMessages } from "@tabler/icons-react"

import { UserRoleType, UserType } from "../types/User"
import React from "react"

export const navigationItems = [
	// {
	// 	label: "Tickets",
	// 	path: "/tickets",
	// 	element: <Tickets />,
	// 	icon: <IconGitPullRequest size={16} />
	// },
	// {
	// 	label: "Inventory",
	// 	path: "/inventory",
	// 	element: <Inventory />,
	// 	icon: <IconAlertCircle size={16} />
	// },
	{
		label: "Users",
		path: "/users",
		element: <Users />,
		icon: <IconMessages />,
		children: [
			{
				label: "SelectedUser",
				path: "/users/:mode/:userId",
				element: null
			}
		]
	}
]

const allRoutes: RouteObject[] = navigationItems.map((item, index) => ({
	path: item.path,
	element: item.element,
	children: item.children
		? item.children.map((childItem) => ({
				path: childItem.path,
				element: childItem.element
		  }))
		: []
}))

export const getNavigationPerUserRole = (userType: UserRoleType) => {
	switch (userType) {
		case "admin":
		default:
			return navigationItems.filter((route) => route.path !== "/intercom")
	}
}
export const getRoutesPerUserRole = (userType: UserRoleType) => {
	switch (userType) {
		case "user":
			return allRoutes.filter((route) => route.path === "/intercom")
		case "admin":
		default:
			return allRoutes.filter((route) => route.path !== "/intercom")
	}
}
const getUserRoutes = (userType: UserRoleType) =>
	createBrowserRouter([
		{ index: true, path: "/login", element: <Login /> },
		{
			path: "/",
			element: <AppShell />,
			children: getRoutesPerUserRole(userType)
		}
	])

export const AppRooter = () => {
	// const userType = useCurrentUserStore((state) => state.userType)
	return <RouterProvider router={getUserRoutes("admin")} />
}
