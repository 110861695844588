import { AppShell as Shell, Header, LoadingOverlay } from "@mantine/core"
import { Navigate, Outlet } from "react-router-dom"
import { ThemeProvider } from "./Theme"
import { NavBar } from "../components/Navigation/NavBar"
import { Notifications } from "@mantine/notifications"
import { useCurrentUserStore } from "../hooks/useCurrentUser"

export const AppShell = () => {
	const currentUser = useCurrentUserStore((state) => state.currentUser)

	if (!currentUser) {
		return <Navigate to="/login" replace />
	}
	return (
		<ThemeProvider>
			<Shell
				// padding={"xl"}
				// aside={<div style={{ width: 200 }}>Hello</div>}
				fixed
				navbar={<NavBar />}
				styles={(theme) => ({
					main: {
						overflow: "hidden",
						maxHeight: "100vh",
						width: "100%",
						backgroundColor:
							theme.colorScheme === "dark"
								? theme.colors.dark[8]
								: theme.colors.gray[0]
					}
				})}
			>
				<Outlet />
			</Shell>
			<Notifications />
			<LoadingOverlay visible={!currentUser} overlayBlur={2} />
		</ThemeProvider>
	)
}
