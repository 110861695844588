import styled from "@emotion/styled"
import { Filters } from "../components/Filters"
import { useParams } from "react-router-dom"
import { useState } from "react"
import { UserPreview } from "../components/Users/UserPreview/UserPreview"
import { UsersTable } from "../components/Users/UsersTable/UsersTable"
import { useUsersStore } from "../hooks/useUsersStore"
import { useDisclosure } from "@mantine/hooks"
import { AddUserModal } from "../components/Modals/AddUser"
import { UserType } from "../types/User"
import { useCurrentUserStore } from "../hooks/useCurrentUser"
import { ITEM_SPLITTER } from "../system/constants"

const FlexWrapper = styled("div")({
	display: "flex",
	flexDirection: "column",
	width: "100%",
	height: "100%",
	alignItems: "center"
})

export const Users = () => {
	const [emptyFlatNumber, setEmptyFlatNumber] = useState<string>("")
	const [addUserOpened, { open: openAddUserModal, close: closeAddUserModal }] =
		useDisclosure(false, {
			onClose: () => setEmptyFlatNumber("")
		})
	const { userId } = useParams()

	const handleAddUserToFirebase = useUsersStore((state) => state.handleAddUser)
	const currentUser = useCurrentUserStore((state) => state.currentUser)

	const handleAddUser = (data: UserType) => {
		handleAddUserToFirebase(data)
	}
	const hasAdminRole =
		currentUser &&
		currentUser.role.split(ITEM_SPLITTER).some((role) => role === "admin")
	//TODO config role-based routing system
	if (!hasAdminRole) return null

	return (
		<>
			<FlexWrapper>
				{!userId && <Filters openAddUserModal={openAddUserModal} />}
				{userId ? (
					<UserPreview />
				) : (
					<UsersTable
						openAddUserModal={openAddUserModal}
						setEmptyFlatNumber={setEmptyFlatNumber}
					/>
				)}
			</FlexWrapper>
			<AddUserModal
				close={closeAddUserModal}
				opened={addUserOpened}
				handleSubmit={handleAddUser}
				emptyFlatNumber={emptyFlatNumber}
			/>
		</>
	)
}
