import React, { useEffect } from "react"
import {
	IconChevronRight,
	IconChevronLeft,
	IconSettings
} from "@tabler/icons-react"
import { IconLogout } from "@tabler/icons-react"
import {
	UnstyledButton,
	Group,
	Avatar,
	Text,
	Box,
	useMantineTheme,
	ActionIcon,
	Menu,
	Button
} from "@mantine/core"
import { useCurrentUserStore } from "../../hooks/useCurrentUser"
import { useUsersStore } from "../../hooks/useUsersStore"

export const NavFooter = () => {
	const theme = useMantineTheme()
	const currentUser = useCurrentUserStore((state) => state.currentUser)
	const logout = useCurrentUserStore((state) => state.logout)

	if (!currentUser) return null

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				paddingTop: theme.spacing.sm,
				borderTop: `1px solid ${
					theme.colorScheme === "dark"
						? theme.colors.dark[4]
						: theme.colors.gray[2]
				}`
			}}
		>
			<Menu shadow="md" width={200}>
				<Menu.Target>
					<UnstyledButton>
						<Group>
							<Box sx={{ flex: 1 }}>
								<Text size="sm" weight={500}>
									{`${currentUser.name} ${currentUser.surname}`}
								</Text>
								<Text color="dimmed" size="sm">
									{`${
										currentUser.email
											? currentUser.email
											: currentUser.phoneNumber
									}`}
								</Text>
							</Box>
						</Group>
					</UnstyledButton>
				</Menu.Target>

				<Menu.Dropdown>
					{/* <Menu.Label>Application</Menu.Label> */}
					<Menu.Item disabled icon={<IconSettings size={14} />}>
						Settings
					</Menu.Item>
					<Menu.Item icon={<IconLogout size={14} />} onClick={logout}>
						Logout
					</Menu.Item>
				</Menu.Dropdown>
			</Menu>

			{/* <ActionIcon onClick={logout}>
				<IconLogout />
			</ActionIcon> */}
		</Box>
	)
}
