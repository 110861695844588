import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { UserRoleType, UserType } from "../types/User"
import { firebaseAuth, firestoreApi } from "../api/auth"
import {
	collection,
	getDocs,
	orderBy,
	query,
	where,
	doc,
	getDoc,
	updateDoc
} from "firebase/firestore"
import { LV_PHONE_REGION_CODE } from "../system/constants"

interface UseCurrentUserStoreType {
	currentUser: UserType | null
	userRoles: UserRoleType[] | null
	validateUserByPhone: (phoneNumber: string) => Promise<boolean>
	loadUser: (phoneNumber: string) => void
	logout: () => Promise<void>
}

const loadUserByPhoneNumber = async (phoneNumber: string) => {
	const ref = collection(firestoreApi, "users")
	const queryRef = query(
		ref,
		orderBy("surname"),
		where("phoneNumber", "==", phoneNumber)
	)
	const userDoc = await getDocs(queryRef)
	return userDoc
}
export const getParsedPhoneNumber = (number: string) => {
	const normalized = number
		.replace(/\s/g, "")
		.split(LV_PHONE_REGION_CODE)
		.join("")
	return [`${LV_PHONE_REGION_CODE}${normalized}`, normalized]
}

export const useCurrentUserStore = create<UseCurrentUserStoreType>()(
	devtools(
		(set) => ({
			currentUser: null,
			userRoles: [],

			validateUserByPhone: async (phoneNumber) => {
				let isValid = false
				const userDoc = await loadUserByPhoneNumber(phoneNumber)
				if (userDoc.docs.length) {
					// user = userDoc.docs[0].data() as unknown as UserType
					isValid = true
				}

				return isValid
			},
			logout: async () => {
				await firebaseAuth.signOut()
				set(() => ({ currentUser: null }), false, "SIGN_OUT")
			},
			loadUser: async (phoneNumber) => {
				// get().users.find((usr) => usr.id === id)
				const userDoc = await loadUserByPhoneNumber(phoneNumber)
				if (userDoc.docs.length) {
					const lastLogin = new Date().getTime().toString()
					const userData = userDoc.docs[0]
					const currentUser = {
						...userData.data(),
						id: userData.id,
						lastLogin
					} as unknown as UserType

					const userRef = doc(firestoreApi, "users", userData.id)
					await updateDoc(userRef, {
						lastLogin
					})
					set(
						() => ({
							currentUser: currentUser
						}),
						false,
						"LOAD_USER"
					)
				}
			}
		}),
		{
			name: "useCurrentUserStore"
		}
	)
)
