import React from "react"
import {
	Group,
	ActionIcon,
	useMantineColorScheme,
	Box,
	Title
} from "@mantine/core"
import { IconSun, IconMoonStars } from "@tabler/icons-react"

export function NavHeader() {
	const { colorScheme, toggleColorScheme } = useMantineColorScheme()

	return (
		<Box
			sx={(theme) => ({
				paddingLeft: theme.spacing.xs,
				paddingRight: theme.spacing.xs,
				paddingBottom: theme.spacing.xl,
				marginBottom: theme.spacing.xl,
				borderBottom: `1px solid ${
					theme.colorScheme === "dark"
						? theme.colors.dark[4]
						: theme.colors.gray[2]
				}`
			})}
		>
			<Group position="apart">
				<Title order={2}>AB38</Title>
				<ActionIcon
					variant="default"
					onClick={() => toggleColorScheme()}
					size={30}
				>
					{colorScheme === "dark" ? <IconSun /> : <IconMoonStars />}
				</ActionIcon>
			</Group>
		</Box>
	)
}
