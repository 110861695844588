import { Modal, Group, Button, Title } from "@mantine/core"
import { FC } from "react"
import { Text } from "@mantine/core"
import { IconTrash } from "@tabler/icons-react"

type ConfirmationDialogProps = {
	onClose: () => void
	onConfirm: () => void
	opened: boolean
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
	opened,
	onClose,
	onConfirm
}) => {
	return (
		<Modal
			opened={opened}
			onClose={onClose}
			centered
			withCloseButton={false}
			size="sm"
		>
			<Title order={2} mb="md" color="red">
				Alert
			</Title>
			<Text>Are you sure want to continue?</Text>
			<Text>This action is irreversible.</Text>
			<Group position="right" mt="xl">
				<Button onClick={onClose} color="gray">
					Cancel
				</Button>
				<Button color="red" onClick={onConfirm} leftIcon={<IconTrash />}>
					Delete
				</Button>
			</Group>
		</Modal>
	)
}
