import { create } from "zustand"
import { devtools } from "zustand/middleware"
import {
	collection,
	getDocs,
	query,
	orderBy,
	addDoc,
	updateDoc,
	where,
	doc,
	getDoc,
	deleteDoc
} from "firebase/firestore"
import { firestoreApi } from "../../api/auth"
import { FirebaseUserRecord, UserType } from "../../types/User"
import { ModeType, UsersStorageState } from "./types"
import {
	applyCurrentFilters,
	getFlatsArray,
	getMappedFlatsPerUsers
} from "./utils"

export const useUsersStore = create<UsersStorageState>()(
	devtools(
		(set, get) => ({
			users: [],
			filteredUsers: [],
			filteredFlats: [],
			flats: [],
			mode: "users",
			currentPage: 1,
			stats: {
				flatsCount: 0,
				usersCount: 0
			},
			filters: {
				biedribaStatus: "All",
				floor: "All",
				ownershipStatus: "All",
				search: ""
			},
			validateUserByPhone: async (phoneNumber: string) => {
				let isValid = false
				const ref = collection(firestoreApi, "users")
				const queryRef = query(
					ref,
					orderBy("surname"),
					where("phoneNumber", "==", phoneNumber)
				)
				const userDoc = await getDocs(queryRef)
				if (userDoc.docs.length) {
					// user = userDoc.docs[0].data() as unknown as UserType
					isValid = true
				}

				return isValid
			},
			getUser: async (id) => {
				// get().users.find((usr) => usr.id === id)
				const userRef = doc(firestoreApi, "users", id)
				const user = await getDoc(userRef)
				const userData = user.data() as UserType
				return userData
			},
			initStore: async () => {
				const ref = collection(firestoreApi, "users")
				const queryRef = query(ref, orderBy("surname"))
				const snapshot = await getDocs(queryRef)
				const data = snapshot.docs.map((doc) => ({
					...doc.data(),
					id: doc.id
				}))
				const users = data as unknown as UserType[]
				const flatsArray = getFlatsArray(users)
				const flats = getMappedFlatsPerUsers(users)

				const currentMode = get().mode
				const currentFilters = get().filters
				const isUserMode = currentMode === "users"
				const targetValue = isUserMode ? [...users] : [...flats]
				const filteredValue = applyCurrentFilters(targetValue, currentFilters)
				set(
					() => {
						return {
							flats,
							users,
							filteredUsers: isUserMode
								? (filteredValue as UserType[])
								: (users as UserType[]),
							filteredFlats: !isUserMode ? filteredValue : flats,
							stats: {
								flatsCount: flatsArray.length,
								usersCount: users.length
							}
						}
					},
					false,
					"initStore"
				)
			},
			handleAddUser: async (user: FirebaseUserRecord) => {
				await addDoc(collection(firestoreApi, "users"), {
					...user
				})
				await get().initStore()
			},
			handleDeleteUser: async (id) => {
				const userRef = doc(firestoreApi, "users", id)
				await deleteDoc(userRef)
				await get().initStore()
			},
			handleUpdateUser: async (id, data) => {
				const userRef = doc(firestoreApi, "users", id)
				await updateDoc(userRef, {
					...data,
					lastUpdated: new Date().getTime().toString()
				})
				await get().initStore()
				await get().getUser(id)
			},
			setCurrentPage: (pageNumber: number) => {
				set(() => ({ currentPage: pageNumber }), false, "setCurrentPage")
			},
			handleModeChange: (mode: ModeType) => {
				set(
					(state) => ({
						mode: mode,
						currentPage: 1,
						filteredFlats: state.flats,
						filteredUsers: state.users,
						filters: {
							search: "",
							biedribaStatus: "All",
							floor: "All",
							ownershipStatus: "All"
						}
					}),
					false,
					"handleModeChange"
				)
			},
			handleFiltersChange: (filterName, value) => {
				set(
					() => {
						const currentMode = get().mode
						const users = get().users
						const flats = get().flats
						const prevFilters = get().filters
						const currentFilters = { ...prevFilters, [filterName]: value }
						const isUserMode = currentMode === "users"
						let targetValue = isUserMode ? [...users] : [...flats]
						let filteredValue = applyCurrentFilters(targetValue, currentFilters)

						const updatedState: Partial<UsersStorageState> = {
							currentPage: 1,
							...(isUserMode
								? { filteredUsers: filteredValue as UserType[] }
								: { filteredFlats: filteredValue }),
							filters: currentFilters
						}
						return updatedState
					},
					false,
					"handleFiltersChange"
				)
			}
		}),
		{
			name: "useUsersStore"
		}
	)
)
