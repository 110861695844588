import styled from "@emotion/styled"
import { Pagination, Paper, SegmentedControl, Text } from "@mantine/core"
import { FC, useEffect, useMemo, useState } from "react"
import { useUsersStore } from "../../../hooks/useUsersStore"
import { Table } from "../../Table"
import { CORPUS_0_FLATS, ITEMS_PER_PAGE } from "../../../system/constants"
import { UserType } from "../../../types/User"

const StyledPagination = styled(Pagination)({
	height: 40,
	margin: "20px 0px 0px 0px"
})
const TotalCount = styled("div")({
	width: "100%",
	height: 60,
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	color: "#333"
})

type UserTableProps = {
	openAddUserModal: () => void
	setEmptyFlatNumber: (number: string) => void
}

export const UsersTable: FC<UserTableProps> = ({
	openAddUserModal,
	setEmptyFlatNumber
}) => {
	const initStore = useUsersStore((state) => state.initStore)
	const users = useUsersStore((state) => state.users)

	// const [currentPage, setCurrentPage] = useState(1)
	const filteredUsers = useUsersStore((state) => state.filteredUsers)
	const filteredFlats = useUsersStore((state) => state.filteredFlats)
	const stats = useUsersStore((state) => state.stats)
	const currentMode = useUsersStore((state) => state.mode)
	const handleModeChange = useUsersStore((state) => state.handleModeChange)

	const currentPage = useUsersStore((state) => state.currentPage)
	const setCurrentPage = useUsersStore((state) => state.setCurrentPage)

	const totalFlatsCount = CORPUS_0_FLATS.length

	const data = useMemo(() => {
		return currentMode === "users" ? filteredUsers : filteredFlats
	}, [currentMode, filteredFlats, filteredUsers])

	const pageCount = useMemo(() => {
		const itemsLength = data.length
		return Math.ceil(itemsLength / ITEMS_PER_PAGE)
	}, [data])

	const filteredData = useMemo(() => {
		const currentPageItemsIndex = ITEMS_PER_PAGE * (currentPage - 1)
		const result = [...data].slice(
			currentPageItemsIndex,
			ITEMS_PER_PAGE * currentPage
		) as unknown as UserType[]
		return result
	}, [currentPage, data])

	useEffect(() => {
		if (!users.length) {
			initStore()
		}
	}, [users])

	return (
		<>
			<StyledPagination
				total={pageCount || 1}
				value={!!pageCount ? currentPage : 0}
				disabled={!pageCount}
				onChange={setCurrentPage}
			/>
			<TotalCount>
				<SegmentedControl
					size="sm"
					value={currentMode}
					onChange={handleModeChange}
					data={[
						{ label: "Users", value: "users" },
						{ label: "Flats", value: "flats" }
					]}
				/>
				<Text c="dimmed">
					Filtered results: {data.length} | Users count: {stats.usersCount} |
					Flats count: {stats.flatsCount} | Total flats: {totalFlatsCount}
				</Text>
			</TotalCount>

			<Paper style={{ width: "100%" }}>
				<Table
					users={[...filteredData]}
					openAddUserModal={openAddUserModal}
					setEmptyFlatNumber={setEmptyFlatNumber}
				/>
			</Paper>
		</>
	)
}
