import { Container, LoadingOverlay, Space, Stack, Text } from "@mantine/core"
import { LoginForm } from "../components/Forms/LoginForm"
import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import { firebaseAuth } from "../api/auth"
import {
	getParsedPhoneNumber,
	useCurrentUserStore
} from "../hooks/useCurrentUser"
import { Navigate } from "react-router-dom"
//@ts-ignore
const bgPic1 = require("../assets/login_img.png")
const logo = require("../assets/logo.png")

const StyledContainer = styled("div")({
	height: "100vh",
	maxHeight: "100vh",
	width: "100%",
	display: "flex",
	alignItems: "center",
	overflow: "hidden"
})
const FormWrapper = styled(Stack)({
	height: "100%",
	flex: 1,
	background: "#FFF",
	// background: "rgba(44, 54, 92, 1)",
	zIndex: 99
})
const StyledBackground = styled("div")({
	height: "100%",
	width: "60%",
	alignItems: "center",
	background: `linear-gradient(0deg, rgba(44, 54, 92, 0.5), rgba(44, 54, 92, 0.5)),url(${bgPic1}) `,
	backgroundSize: "cover",
	backgroundPosition: "center",
	transform: "scale(1.1)"
})

export function Login() {
	const [isPageLoading, setIsPageLoading] = useState(true)
	const loadUser = useCurrentUserStore((state) => state.loadUser)
	const currentUser = useCurrentUserStore((state) => state.currentUser)
	useEffect(() => {
		// firebaseAuth.signOut()
		firebaseAuth.onAuthStateChanged(async (state) => {
			if (state && state.phoneNumber) {
				const [, phoneId] = getParsedPhoneNumber(state.phoneNumber)
				await loadUser(phoneId)
				setIsPageLoading(false)
			} else {
				setIsPageLoading(false)
			}
		})
	}, [])

	if (currentUser) {
		return <Navigate to="/users" replace />
	}
	return (
		<StyledContainer>
			<StyledBackground />

			<FormWrapper align="center">
				<img
					src={logo}
					alt=""
					draggable={false}
					style={{ width: 250, height: 125, marginTop: 100 }}
				/>
				<div style={{ width: "100%", flex: 1, position: "relative" }}>
					<LoadingOverlay visible={isPageLoading} overlayBlur={2} />
					<LoginForm setIsPageLoading={setIsPageLoading} />
				</div>
				<div
					style={{
						height: 40,
						width: "100%",
						display: "flex",
						justifyContent: "center"
					}}
				>
					<Text c="dimmed">2023 | AB38 Biedriba | valde@ab38.lv</Text>
				</div>
			</FormWrapper>
		</StyledContainer>
	)
}
