import { CORPUS_0_FLATS, ITEM_SPLITTER } from "../../system/constants"
import { UserType } from "../../types/User"
import { FiltersType } from "./types"

const keysForSearch = [
	"name",
	"surname",
	"flatNumber",
	"internalParking",
	"externalParking",
	"phoneNumber"
]

export const getFloorPerFlatNumber = (flat: string) => {
	return flat.length > 2 ? flat.slice(0, 2) : flat[0]
}

export const getUserFlatsArray = (flatNumber: string) => {
	if (!flatNumber) return []
	const userFlats = flatNumber.split(ITEM_SPLITTER)
	return userFlats.map(getFloorPerFlatNumber)
}
export const getFloorNumberString = (flatNumber: string) => {
	if (!flatNumber) return "-"
	const floors = getUserFlatsArray(flatNumber)
	const hasSingleFloorNumber = floors.length === 1 || floors[0] === floors[1]
	return hasSingleFloorNumber ? floors[0] : `${floors[0]},${floors[1]}`
}

export const getFlatsArray = (usersArray: UserType[]) => {
	return usersArray
		.map((usr) => usr.flatNumber)
		.reduce((acc: string[], current) => {
			if (current.includes(ITEM_SPLITTER)) {
				const flats = current.split(ITEM_SPLITTER)
				return [...acc, ...flats]
			} else {
				return [...acc, current]
			}
		}, [])
}

export const getFilteredFlatsByFlatNumber = (
	usr: UserType,
	searchValue: string
) => {
	return usr.flatNumber.includes(searchValue)
}
export const getFilteredUsersBySearch = (
	usr: Partial<UserType>,
	searchValue: string
) => {
	return keysForSearch.some((key) => {
		const fieldValue = usr[key as keyof UserType]
		if (typeof fieldValue !== "string") return
		if (key === "phoneNumber") return fieldValue === searchValue
		return fieldValue.includes(searchValue)
	})
}
export const getFilteredUsersByFloor = (
	usr: Partial<UserType>,
	floorNumber: string
) => {
	if (floorNumber === "All") return true
	if (!usr.flatNumber) return false
	const usrFloorsArray = getUserFlatsArray(usr.flatNumber)
	return usrFloorsArray.some((floor) => floor === floorNumber)
}
export const getFilteredUsersByOwnershipStatus = (
	usr: Partial<UserType>,
	status: string
) => {
	if (status === "All") return true
	if (!usr.ownershipStatus) return false
	return usr.ownershipStatus.toLocaleLowerCase() === status.toLocaleLowerCase()
}
export const getFilteredUsersByMembershipStatus = (
	usr: Partial<UserType>,
	status: string
) => {
	if (status === "All") return true
	if (status === "Biedriba member") return usr.isMemberOfBiedriba
	if (status === "Board member") return usr.isBoardMember
}

export const getProperFilter =
	(filterName: string, value: string) =>
	(usr: UserType | Partial<UserType>) => {
		switch (filterName) {
			case "search":
				return getFilteredUsersBySearch(usr, value)
			case "floor":
				return getFilteredUsersByFloor(usr, value)
			case "ownershipStatus":
				return getFilteredUsersByOwnershipStatus(usr, value)
			case "biedribaStatus":
				return getFilteredUsersByMembershipStatus(usr, value)
			default:
				throw new Error("No filter name specified.")
		}
	}

export const getMappedFlatsPerUsers = (users: UserType[]) => {
	return CORPUS_0_FLATS.map((corpusFlatNumber) => {
		const flatsArray = getFlatsArray(users)
		const existingFlat = flatsArray.find(
			(flatNumber) => +flatNumber === +corpusFlatNumber
		)
		if (existingFlat) {
			const targetUser = users.find((usrData) =>
				usrData.flatNumber.split(",").some((val) => +val === +corpusFlatNumber)
			)
			return { ...targetUser, flatNumber: `${corpusFlatNumber}` }
		}
		return { flatNumber: `${corpusFlatNumber}` }
	})
}

export const applyCurrentFilters = (
	values: UserType[] | Partial<UserType>[],
	currentFilters: FiltersType
) => {
	let filteredValue = [...values]
	for (const [name, value] of Object.entries(currentFilters)) {
		filteredValue = [...filteredValue].filter(getProperFilter(name, value))
	}

	return filteredValue
}
