import React from "react"
import {
	IconGitPullRequest,
	IconAlertCircle,
	IconMessages,
	IconDatabase
} from "@tabler/icons"
import { ThemeIcon, UnstyledButton, Group, Text } from "@mantine/core"
import {
	navigationItems,
	getNavigationPerUserRole
} from "../../system/AppRouter"
import { NavLink, useLocation } from "react-router-dom"
import styled from "@emotion/styled"
import { useCurrentUserStore } from "../../hooks/useCurrentUser"

interface MainLinkProps {
	icon: React.ReactNode
	label: string
	path: string
}

const StyledNavLink = styled(NavLink)((props) => ({
	textDecoration: "none",
	color: "inherit"
}))

function MainLink({ icon, label, path }: MainLinkProps) {
	let location = useLocation()
	const isActiveLink = location.pathname.includes(path)
	return (
		<StyledNavLink
			to={path}
			style={({ isActive }) => ({
				backgroundColor: isActive ? "red !important" : "inherit"
			})}
		>
			<UnstyledButton
				sx={(theme) => ({
					display: "block",
					width: "100%",
					padding: theme.spacing.xs,
					borderRadius: theme.radius.sm,
					background: isActiveLink
						? theme.colorScheme === "dark"
							? theme.colors.dark[5]
							: theme.colors.gray[1]
						: "inherit",
					color:
						theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

					"&:hover": {
						backgroundColor:
							theme.colorScheme === "dark"
								? theme.colors.dark[6]
								: theme.colors.gray[0]
					}
				})}
			>
				<Group>
					<ThemeIcon color={"blue"} variant="light">
						{icon}
					</ThemeIcon>
					<Text size="xl">{label}</Text>
				</Group>
			</UnstyledButton>
		</StyledNavLink>
	)
}

export function NavLinks() {
	const links = getNavigationPerUserRole("admin").map((item) => (
		<MainLink {...item} key={item.label} />
	))
	return <div style={{ flex: 1 }}>{links}</div>
}
